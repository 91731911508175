import $ from "jquery";
import "foundation-sites/dist/css/foundation.css";
import "foundation-sites/dist/css/foundation-prototype.css";
import "../uktraining-frontend/src/css/style.css";
import "foundation-sites/dist/js/foundation.min.js"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel";
import "jquery-ui/dist/jquery-ui";
import "jquery-ui/dist/themes/base/jquery-ui.css";

export function onClientEntry() {
  window.jQuery = $;
  window.$ = $;
  $(function () {
    $(document).foundation();
  });
  
  const iCheckStylesheet = document.createElement("link");
  iCheckStylesheet.rel = "stylesheet";
  iCheckStylesheet.href = "https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.2/skins/all.css";
  document.head.appendChild(iCheckStylesheet);

  const iCheckScript = document.createElement("script");
  iCheckScript.src = "https://cdnjs.cloudflare.com/ajax/libs/iCheck/1.0.2/icheck.min.js";
  iCheckScript.async = true;
  document.body.appendChild(iCheckScript);

  const script = document.createElement("script");
  const ticker = document.createElement("script");
  const productListScript = document.createElement("script");
  const jQueryModalJS = document.createElement("script");
  const formValidation = document.createElement("script");
  const formValidationPopular = document.createElement("script");

  script.src = "/default.js";
  ticker.src = "/jquery.ticker.min.js";
  productListScript.src = "/product_list.js"
  jQueryModalJS.src = "/jquery.modal.min.js";
  formValidation.src = "/formValidation.min.js";
  formValidationPopular.src = "/formValidation.popular.min.js";  

  document.body.appendChild(jQueryModalJS);
  document.body.appendChild(ticker);
  document.body.appendChild(formValidation);
  document.body.appendChild(formValidationPopular);
  document.body.appendChild(script);
  document.body.appendChild(productListScript);
}

export const onRouteUpdate = ({ location }) => {
  if (location.pathname.startsWith("/training")) {
    const googleCaptcha = document.createElement("script");
    const recaptchaJS = document.createElement("script");
    const productJS = document.createElement("script");
    
    googleCaptcha.src = "https://www.google.com/recaptcha/api.js";
    recaptchaJS.src = "/recaptcha.js";
    productJS.src = "/product_js.js";
    
    document.body.appendChild(googleCaptcha);
    document.body.appendChild(recaptchaJS);
    document.body.appendChild(productJS);
  }
};